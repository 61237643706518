<template>
    <div
        ref="tooltip"
        v-click-outside="hide"
        class="toolTip"
        :class="[{active: modelValue, deskStyle: floatStyle}, positionModify]"
        :style="{width: width ? (width + 'px') : null, color: textColor}"
    >
        <div v-if="useSmoothCorners" class="smoothCorner corner">
            <div :style="{backgroundColor: bgColor, borderLeftColor: bgColor}"/>
        </div>

        <div v-smooth-corners="smoothCornersSettings" class="toolTipContent" :style="{backgroundColor: bgColor}">
            <div class="corner" :style="{borderRightColor: bgColor}"/>
            <div class="closeButton @cursor-pointer" @click.stop="hide"/>
            <slot/>
        </div>
    </div>
</template>

<script>
    import click_oustide_directive from "../mixins/click_oustide_directive";
    import vSmoothCorners from "@songfinch/shared/plugins/smooth_corners/smooth_corners_directive";
    import {debounce} from "lodash-es";

    export default {
        name: "ToolTip",
        directives: {smoothCorners: vSmoothCorners},
        mixins: [click_oustide_directive],
        props: {
            modelValue: {
                type: Boolean,
                required: true
            },
            position: {
                validator(value) {
                    return ["top", "left", "bottom", "right", ""].includes(value);
                },
                type: String,
                default: "right"
            },
            hideOnClickOutside: {
                type: Boolean,
                default: true
            },
            floatStyle: {
                type: Boolean,
                default: false
            },
            bgColor: {
                type: String,
                default: ""
            },
            textColor: {
                type: String,
                default: ""
            },
            width: {
                type: [String, Number],
                default: ""
            },
            minWidth: {
                type: String,
                default: "210"
            },
            useSmoothCorners: {
                type: [Boolean, Object],
                default: false
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {
                debounceFunc: null,
                positionModify: this.position,
                transformPos: {
                    bottom: {
                        cr: "translateX(-50%)",
                        rt: "rotate(90deg)"
                    },
                    top: {
                        cr: "translateX(-50%)",
                        rt: "rotate(-90deg)"
                    },
                    left: {
                        cr: "translateY(-50%)",
                        rt: "rotate(180deg)"
                    },
                    right: {
                        cr: "translateY(-50%)",
                        rt: "rotate(0)"
                    }
                }
            };
        },
        computed: {
            isVertical() {
                return ["bottom", "top"].includes(this.positionModify);
            },
            smoothCornersSettings() {
                if (!this.useSmoothCorners) return false;
                if (this.useSmoothCorners === true) return {cornerRadius: 16};
                return this.useSmoothCorners;
            }
        },
        watch: {
            modelValue(val) {
                this.updateBodyClass(val);
            }
        },
        mounted() {
            this.debounceFunc = debounce(this.fitTooltip, 500);
            window.addEventListener("resize", this.debounceFunc);
            setTimeout(this.fitTooltip);
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.debounceFunc);
            if (this.modelValue) {
                this.updateBodyClass(false);
            }
        },
        methods: {
            hide() {
                if (this.hideOnClickOutside) this.$emit("update:modelValue", false);
            },
            updateBodyClass(val) {
                document.body.classList.toggle("sfShowTooltip", val);
            },
            async fitTooltip() {
                if (!this.floatStyle) return;
                const tltp = this.$refs.tooltip;
                if (!tltp) return;
                const dempfer = 10;
                const corner = tltp.querySelector(".corner");
                const tooltipcontent = tltp.querySelector(".toolTipContent");
                const winWidth = document.documentElement.clientWidth;
                this.clear(tltp, corner);
                const fd = this.freshData(tooltipcontent, winWidth, dempfer);
                if (fd.right >= winWidth) { // resize right
                    if (this.isVertical) {
                        this.transformX(tltp, corner, fd.outWidtRight, fd.halfWidth - 15);
                        if (Math.abs(fd.outWidtRight) * 100 / fd.halfWidth > 50) {
                            corner.classList.add("cornRight");
                            corner.classList.remove("cornLeft");
                        }
                    }
                    if (this.positionModify === "right") {
                        const widthReg = fd.width - fd.outWidtRight;
                        if (widthReg > +this.minWidth + 60) {
                            tltp.style.width = `${widthReg}px`;
                        } else {
                            this.positionModify = "bottom";
                            this.clear(tltp, corner);
                            await this.$nextTick();
                            const nd = this.freshData(tooltipcontent, winWidth, dempfer);
                            if (nd.right > winWidth) {
                                this.transformX(tltp, corner, nd.outWidtRight, nd.halfWidth - 15);
                                if (Math.abs(nd.outWidtRight) * 100 / nd.halfWidth > 50) {
                                    corner.classList.add("cornRight");
                                    corner.classList.remove("cornLeft");
                                }
                            }
                        }
                    }
                }
                if (fd.left < 0) { // resize left
                    if (this.isVertical) {
                        this.transformX(tltp, corner, fd.outWidtLeft, fd.halfWidth + 15);
                        if (Math.abs(fd.outWidtLeft) * 100 / fd.halfWidth > 50) {
                            corner.classList.add("cornLeft");
                            corner.classList.remove("cornRight");
                        }
                    }
                    if (this.positionModify === "left") {
                        const widthReg = fd.width + fd.left;
                        if (widthReg > +this.minWidth + 30) {
                            tltp.style.width = `${widthReg}px`;
                        } else {
                            this.positionModify = "bottom";
                            this.clear(tltp, corner);
                            await this.$nextTick();
                            const nd = this.freshData(tooltipcontent, winWidth, dempfer);
                            if (nd.left < 0) {
                                this.transformX(tltp, corner, nd.outWidtLeft, nd.halfWidth + 15);
                                if (Math.abs(nd.outWidtRight) * 100 / nd.halfWidth > 50) {
                                    corner.classList.add("cornRight");
                                    corner.classList.remove("cornLeft");
                                }
                            }
                        }
                    }
                }
            },
            freshData(tooltipcontent, winWidth, dempfer) {
                const {left, right, width} = tooltipcontent.getBoundingClientRect();
                return {
                    left,
                    right,
                    width,
                    halfWidth: width / 2,
                    outWidtRight: (right - winWidth) + dempfer,
                    outWidtLeft: left - dempfer
                };
            },
            transformX(toolRef, cornerRef, outWidth, halfWidth) {
                toolRef.style.transform = `translateX(calc(-50% - ${Math.abs(outWidth) > halfWidth ? halfWidth : outWidth}px))`;
                cornerRef.style.transform = `translateX(calc(-50% + ${Math.abs(outWidth) > halfWidth ? halfWidth - 17 : outWidth}px)) ${this.transformPos[this.positionModify].rt}`;
            },
            clear(toolRef, cornerRef) {
                toolRef.style.transform = this.transformPos[this.positionModify].cr;
                cornerRef.style.transform = `${this.transformPos[this.positionModify].cr} ${this.transformPos[this.positionModify].rt}`;
                if (this.isVertical) toolRef.style.width = this.width ? this.width + "px" : "310px";
                cornerRef.classList.remove("cornRight");
                cornerRef.classList.remove("cornLeft");
            }
        }
    };
</script>

<style scoped>
    .toolTip {
        cursor: auto;
        text-transform: none;
        z-index: 9;
        pointer-events: none;

        .toolTipContent {
            pointer-events: all;
        }

        @screen mobile {
            &.deskStyle {
                max-width: 100vw;
            }
        }

        .smoothCorner {
            position: absolute;
            top: 50%;
            left: 22px;
            z-index: 5;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;

            div {
                position: absolute;
                top: 0;
                left: 0;
                background-color: var(--sfc-white);
                width: 16px;
                height: 16px;
                border-top-right-radius: 30%;
                border-bottom-left-radius: 30%;
                transform: rotate(-150deg) skewX(-30deg) scale(1, .866);
            }
        }

        &.top {
            .smoothCorner {
                top: auto;
                bottom: 22px;
                left: 50%;
                transform: translateX(-50%) rotate(90deg);

                &.cornLeft, &.cornRight {
                    z-index: -1;

                    > div {
                        width: 0;
                        height: 0;
                        left: 2px;
                        top: -15px;
                        border-radius: 0;
                        border-right-color: transparent;
                        border-top: 16px solid transparent;
                        border-bottom: 16px solid transparent;
                        border-left: 16px solid;
                        transform: rotate(90deg);
                        background: transparent !important;
                    }
                }

                &.cornRight {
                    > div {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        &.left {
            .smoothCorner {
                left: auto;
                right: 22px;
            }
        }

        &.bottom {
            .smoothCorner {
                top: 22px;
                left: 50%;
                transform: translateX(-50%) rotate(90deg);

                &.cornLeft, &.cornRight {
                    z-index: -1;

                    > div {
                        width: 0;
                        height: 0;
                        left: 2px;
                        top: -15px;
                        border-radius: 0;
                        border-right-color: transparent;
                        border-top: 16px solid transparent;
                        border-bottom: 16px solid transparent;
                        border-left: 16px solid;
                        transform: rotate(-90deg);
                        background: transparent !important;
                    }
                }

                &.cornRight {
                    > div {
                        top: 0;
                        transform: rotate(90deg);
                    }
                }
            }
        }

        @screen tablet-mobile {
            &:not(.deskStyle) {
                z-index: 9999;
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 30px 45px;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 0 23px rgba(0, 0, 0, 0.05);
                border-radius: 10px 10px 0 0;
                transform: translateY(150%);
                transition: opacity 0.3s;

                &.active:not(.close) {
                    transform: translateY(0);
                }
            }

            &.deskStyle {
                position: absolute;
                left: calc(100% - 10px);
                top: 50%;
                transform: translateY(-50%);
                background: none;
                width: 260px;
                padding: 30px;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;

                .toolTipContent {
                    width: 100%;
                    position: relative;
                    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
                    padding: 16px;
                    border-radius: 10px;
                    display: inline-block;
                    text-transform: none;
                    background-color: var(--sfc-white);

                    .closeButton {
                        display: none;
                    }

                    .corner {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        border: solid 15px transparent;
                        border-right-color: var(--sfc-white);
                        right: auto;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -25px;
                        pointer-events: none;
                    }
                }

                &.left {
                    left: auto;
                    right: calc(100% - 10px);
                    direction: rtl;

                    .toolTipContent .corner {
                        left: auto;
                        right: -25px;
                        transform: translateY(-50%) rotate(180deg);
                    }
                }

                &.bottom {
                    left: 50%;
                    right: auto;
                    top: calc(100% - 10px);
                    transform: translateX(-50%);

                    .toolTipContent .corner {
                        left: 50%;
                        right: auto;
                        top: -25px;
                        transform: translateX(-50%) rotate(90deg);
                    }
                }

                &.top {
                    left: 50%;
                    right: auto;
                    top: auto;
                    bottom: calc(100% - 10px);
                    transform: translateX(-50%);

                    .toolTipContent .corner {
                        left: 50%;
                        right: auto;
                        top: auto;
                        bottom: -25px;
                        transform: translateX(-50%) rotate(-90deg);
                    }
                }

                &.active {
                    opacity: 1;
                    height: auto;
                    visibility: visible;
                    transition: opacity 0.3s;
                }
            }
        }

        @screen desktop {
            position: absolute;
            top: 50%;
            left: calc(100% - 10px);
            visibility: hidden;
            width: 310px;
            overflow: hidden;
            opacity: 0;
            padding: 30px;
            transition: opacity 0.3s;
            transform: translateY(-50%);

            .closeButton {
                display: none;

                &:before, &:hover {
                    background-color: #83817B;
                }
            }

            .toolTipContent {
                position: relative;
                width: 100%;
                white-space: normal;
                filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
                background: var(--sfc-white);
                padding: 16px;
                border-radius: 10px;
                display: inline-block;
                text-align: left;
                direction: ltr;
                text-transform: none;

                .corner {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -25px;
                    z-index: 1;
                    border: solid 15px transparent;
                    border-right-color: var(--sfc-white);
                }
            }

            &.left {
                left: auto;
                right: calc(100% - 10px);
                direction: rtl;

                .toolTipContent .corner {
                    left: auto;
                    right: -25px;
                    transform: translateY(-50%) rotate(180deg);
                }
            }

            &.bottom {
                left: 50%;
                right: auto;
                top: calc(100% - 10px);
                transform: translateX(-50%);

                .toolTipContent .corner {
                    left: 50%;
                    right: auto;
                    top: -25px;
                    transform: translateX(-50%) rotate(90deg);
                }
            }

            &.top {
                left: 50%;
                right: auto;
                top: auto;
                bottom: calc(100% - 10px);
                transform: translateX(-50%);

                .toolTipContent .corner {
                    left: 50%;
                    right: auto;
                    top: auto;
                    bottom: -25px;
                    transform: translateX(-50%) rotate(-90deg);
                }
            }

            &.active {
                opacity: 1;
                height: auto;
                visibility: visible;
            }
        }
        /**TODO remove after transfer*/

        &.blackTooltip {
            .toolTipContent {
                background: var(--sfc-black);
                color: var(--sfc-white);

                .corner {
                    border-right-color: var(--sfc-black)
                }
            }
        }
    }
</style>
